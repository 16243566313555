.container { width: 980px !important; }

.top-padd {
	padding-top: 20px;
}

.clear {
	clear:both;
}

body {
	min-width: 1000px;
	padding-bottom: 100px;
}

.tab-content th {
	font-size: 0.85em;
}

/*
.heading {
	min-width: 195px;
}
*/

.heading h1 i {
	margin-right: 3px;
}

h1 {
	margin: 0 0 1em;
	font-size: 25px;
}

nav.navbar.navbar-default.navbar-static-top {
	margin-bottom: 0;
}

.sidebar ul {
	padding-left: 0;
	list-style: none;
}

.sidebar li {
	padding-left: 5px;
}

.sidebar li.active {
	background-color: #eeeeee;
}

#search {
	padding-left: 5px;
	max-width:350px;
}

.icon-space-5 {
	margin-right: 5px;
}

.p-r-5 {
	padding-right: 5px;
}

.show-results {
	float: right;
	padding: 6px 26px;
	max-width: 130px;
	line-height: 1.6;
}

.pagination-wrapper ul {
	margin-top: 0;
	margin-bottom: 0;
}

.filter {
	position: fixed;
	margin: 0;
	padding: 0;
	bottom: -75px;
	width: 100%;
    -webkit-transition: bottom 0.5s;
    transition: bottom 0.5s;
    z-index: 10;
}

.filter.active {
	bottom: 0;
    -webkit-transition: bottom 0.5s;
    transition: bottom 0.5s;
}

.filter.active.initial {
	bottom: 0;
    -webkit-transition: none;
    transition: none;
}

.filter-tab {
	position: absolute;
	max-width: 120px;
	height: 30px;
	margin-top: -29px;
	margin-left: 15px;
	padding: 5px 10px 0;
	background-color: #ffffff;
	font-size: 13px;
	color: #000000;
	cursor: pointer;
	border-top: 1px solid #d3e0e9;
	border-left: 1px solid #d3e0e9;
	border-right: 1px solid #d3e0e9;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.filter-body {
	min-height: 80px;
	border-top: 1px solid #d3e0e9;
	background-color: #ffffff;
	font-size: 12px;
	line-height: 14px;
	overflow: auto;
	overflow-y: hidden;
}

.filter-body table {
	margin-top: 6px;
	margin-left: 15px;
}

.filter-body td {
	padding: 4px 5px 2px;
	width: 90px;
}

.filter-body td input[type=text] {
	width: 90px;
}

#sub-tabs {
	display: none;
	margin-top: 10px;
	font-size: 13px;
}

.tab-content {
	margin-top: 20px;
}

.sub-tab i {
	margin-right: 5px;
	color: #000;
	cursor: pointer;
}

.sub-tab i:hover {
	color: red;
}

.leads-page-mid.active {
	background-color: #eeeeee;
}

.cols-menu {
	width: 700px;
}

.cols-menu ul {
	padding-top: 5px;
	padding-left: 5px;
}

.cols-menu li {
	display: inline-block;
	width: 170px;
	padding: 2px 5px;
	font-size: 11px;
}

.cols-menu li input[type=checkbox] {
	margin-right: 3px;
}

#logo {
	display: block;
	margin: 2em auto;
	width: 300px;
}

.navbar-collapse.collapse {
  display: block!important;
}

.navbar-nav>li, .navbar-nav {
  float: left !important;
}

.navbar-nav.navbar-right:last-child {
  margin-right: -15px !important;
}

.navbar-right {
  float: right!important;
}

.navbar-nav .active {
	background-color: #ffffff;
	border-bottom: 4px solid #afc5d5;
}

.table-striped th a {
	color: rgb(99, 107, 111);
}

#search-sidebar a {
	color: rgb(99, 107, 111);
}

#search-sidebar a:hover {
	color: red;
	text-decoration: none;
}

#bigfilter-sidebar ul {
	margin-bottom: 0;
}

.filter-results .panel-body {
	font-size: 13px;
}

.breadcrumb {
	font-size: 12px;
}

.contact-select {
	width: 100%;
}

#schedulebox {
  position: fixed;
  top: 52px;
  height: calc(100% - 52px);
  width: 100%;
  border: 0; 
}

small a {
	text-decoration: underline;
}

.send-mail-hidden {
	display: none;
}

body .modal {
	/*
	-webkit-transition: width 1s;
	transition: width 1s;
	*/
}

body .bigmodal {
	width: 700px;
	margin-left: -50px;
	/*
	-webkit-transition: width 1s;
	transition: width 1s;
	*/
}

.dropzone {
	border: 2px dashed #0087F7;
	border-radius: 5px;
}

.dropzone .dz-message span {
	font-size: 25px;
	text-align: center;
}

/*
#dropzoneFileUpload {
	font-size: 25px;
	text-align: center;
	min-height: 150px;
	padding: 54px 54px;
	border: 2px dashed #0087F7;
	border-radius: 5px;
	background: #eeeeee;
	color: #999999;
	cursor: pointer;
	box-sizing: border-box;
}

#dropzoneFileUpload i {
	display: block;
	width: 80px;
	margin: 0 auto 20px;
	font-size: 80px;
}

#dropzoneFileUpload .note {
	font-size: 16px;
}
*/

.profile {
	width: 100px;
	height: 100px;
	border-radius: 4px;
}

.profile.big {
	width: 150px;
	height: 150px;
	border-radius: 6px;
}

.profile.small {
	margin-right: 10px;
	width: 26px;
	height: 26px;
	border-radius: 2px;
}

.report-header {
	font-size: 16px;
	text-align: center;
}

#freport .report-column {
	padding: 14px 12px;
}

#freport .report-column th,
#freport .report-column tr.separator td {
	border-bottom: 2px solid #ccc;
}

#freport .report-column td.money {
	text-align: right;
}

#sbsteps .fa-check {
	color: green;
}

.small-fields label,
.small-fields .form-control {
	font-size: 85%;
	font-weight: normal;
}

.small-fields .form-control {
	height: 30px;
	padding: 3px 6px;	
}

.small-fields .form-group {
	margin-bottom: 3px;
}

.alert {
	margin-bottom: 15px;
}

.inner-heading {
	margin-bottom: 15px;
	padding-bottom: 10px;
	color: #000;
	border-bottom: 1px solid rgb(211, 224, 233);
}

.note {
	padding-left: 45px;
	border-top: 1px solid rgb(211, 224, 233);
}

.panel-footer small {
	color: rgb(150, 150, 150);
}

.smallfields input,
.smallfields select {
	font-size: 90%;
}

.smallfields input {
	padding: 3px 6px;
}

.smallrow th {
	font-size: 0.85em;
}

.circlelock {
	padding: 1px 2px 1px 4px;
	border-radius: 14px;
	background-color:#eee;
	border: 1px solid #eee;
}

.circlelock i {
	margin-right: 3px;
}

.circlelock.unlocked {
	cursor: pointer;
}

.circlelock.unlocked:hover {
	border: 1px solid #ccc;
}

.circlelock.locked {
	color: green;
}

.circlelock span {
	font-size: 0.9em;
}

.btn-colorselector {
	border: 1px solid #000;
}

.dropdown-colorselector>.dropdown-menu {
	padding: 5px inherit;
}